

















import { NuxtError } from 'nuxt';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import AlertDialog from '~/components/AlertDialog.vue';

@Component({
  components: {
    AlertDialog
  }
})
export default class ErrorPage extends Vue {
  showErrorDialog: boolean = true;
  errorTitle: string = 'エラー';
  errorMessage: string = 'エラーが発生しました';

  @Prop({ type: Object as PropType<NuxtError> })
  error: NuxtError;

  created () {
    if (this.error && this.error.message && this.error.message !== '{}')
      this.errorMessage = this.error.message;
  }

  closeErrorDialog () {
    if (this.error.statusCode !== 500)
      this.$router.replace('/Login');
  }
}
