import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { accountApi } from '~/api';
import { IUserToken } from '~/models';
import * as utilities from '~/utilities';

@Module({
  name: 'AccountStore',
  stateFactory: true,
  namespaced: true
})
export default class AccountStore extends VuexModule {
  userToken: IUserToken | null = null;

  get needRefresh (): boolean {
    return !this.userToken || utilities.dayjs().isAfter(utilities.dayjs(this.userToken.expiration));
  }

  get isAvailable (): boolean {
    return this.userToken != null && !this.needRefresh;
  }

  @Mutation
  setToken (payload: IUserToken) {
    this.userToken = payload;
  }

  @Mutation
  clearToken () {
    this.userToken = null;
  }

  @Action({ rawError: true })
  async login (payload: {loginId: string, password: string }) {
    const response = await accountApi.login(payload.loginId, payload.password);
    this.setToken(response);
  }

  @Action({ rawError: true })
  async refresh () {
    const response = await accountApi.refresh();
    this.setToken(response);
  }

  @Action({ rawError: true })
  async resetPassword (loginId: string) {
    await accountApi.resetPassword(loginId);
  }

  @Action({ rawError: true })
  async changePassword (payload: { currentPassword: string, newPassword: string, resetPasswordToken: string }) {
    await accountApi.changePassword(payload);
  }
}
