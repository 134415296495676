import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _e0f0f79e = () => interopDefault(import('../src/pages/Account.vue' /* webpackChunkName: "pages/Account" */))
const _5038b730 = () => interopDefault(import('../src/pages/Login/index.vue' /* webpackChunkName: "pages/Login/index" */))
const _c14ba4c6 = () => interopDefault(import('../src/pages/Login/ChangePassword.vue' /* webpackChunkName: "pages/Login/ChangePassword" */))
const _9f1b6d70 = () => interopDefault(import('../src/pages/Login/ResetPassword.vue' /* webpackChunkName: "pages/Login/ResetPassword" */))
const _ec889914 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/Account",
    component: _e0f0f79e,
    name: "Account"
  }, {
    path: "/Login",
    component: _5038b730,
    name: "Login"
  }, {
    path: "/Login/ChangePassword",
    component: _c14ba4c6,
    name: "Login-ChangePassword"
  }, {
    path: "/Login/ResetPassword",
    component: _9f1b6d70,
    name: "Login-ResetPassword"
  }, {
    path: "/",
    component: _ec889914,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config.app && config.app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
