import { Store } from 'vuex';
import { getModule } from 'vuex-module-decorators';

import AppStore from './AppStore';
import AccountStore from './AccountStore';
import ScheduleStore from './ScheduleStore';

let appStore: AppStore;
let accountStore: AccountStore;
let scheduleStore: ScheduleStore;

const initializer = (store: Store<any>) => {
  appStore = getModule(AppStore, store);
  accountStore = getModule(AccountStore, store);
  scheduleStore = getModule(ScheduleStore, store);
};

export const plugins = [initializer];

export {
  initializer,
  appStore,
  accountStore,
  scheduleStore
};
