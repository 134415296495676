import { Plugin } from '@nuxt/types';
import createPersistedState from 'vuex-persistedstate';

const LocalStoragePlugin: Plugin = ({ store }) => {
  createPersistedState({
    key: 'my-youspeak',
    paths: ['AccountStore']
  })(store);
};

export default LocalStoragePlugin;
