import { AxiosInstance } from 'axios';
import { IUserToken } from '~/models';

export class AccountApi {
  constructor (public axios: AxiosInstance) {}

  async login (loginId: string, password: string): Promise<IUserToken> {
    const response = await this.axios.post('auth/login', { loginId, password });
    return response.data as IUserToken;
  }

  async refresh (): Promise<IUserToken> {
    const response = await this.axios.post('auth/refresh');
    return response.data as IUserToken;
  }

  async resetPassword (loginid: string): Promise<void> {
    await this.axios.get(`auth/resetPassword?loginid=${loginid}`);
  }

  async changePassword (payload: { currentPassword: string, newPassword: string, resetPasswordToken: string }): Promise<void> {
    const response = await this.axios.post('auth/changePassword', {
      currentPassword: payload.currentPassword,
      newPassword: payload.newPassword,
      resetPasswordToken: payload.resetPasswordToken
    });

    if (response.status !== 200)
      throw new Error('change password error');
  }
}
