import { AxiosInstance } from 'axios';

export class UserApi {
  constructor (public axios: AxiosInstance) {}

  async checkin (userId: number, scheduleId: number): Promise<string> {
    const response = await this.axios.post('users/onlineCheckin', { userId, scheduleId });

    if (response.status === 200)
      return response.data as string;
    else if (response.status === 204)
      throw new ScheduleNotAvailable();
    else if (response.status === 205)
      throw new ScheduleAlreadyFull();
    else
      throw new Error('checkin error');
  }
}

export class ScheduleNotAvailable extends Error {
  name = 'ScheduleNotAvailable';
}

export class ScheduleAlreadyFull extends Error {
  name = 'ScheduleAlreadyFull';
}
