









































































import { Component, Vue } from 'nuxt-property-decorator';
import { accountStore, appStore } from '~/store';

@Component
export default class DefaultLayouts extends Vue {
  userName: string = '';

  get revision () {
    const env = process.env.NODE_ENV === 'production' ? '' : ` - ${process.env.NODE_ENV}`;
    return appStore.revision + env;
  }

  created () {
    this.userName = accountStore.userToken!.userName;
  }

  logout () {
    accountStore.clearToken();
    this.$router.push('/Login');
  }
}
