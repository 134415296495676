import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators';
import { scheduleApi } from '~/api';
import { ISchedule, ICourseGroup } from '~/models';
import * as utilities from '~/utilities';

@Module({
  name: 'ScheduleStore',
  stateFactory: true,
  namespaced: true
})
export default class ScheduleStore extends VuexModule {
  schedules: ISchedule[] = [];
  courseGroups: ICourseGroup[] = [];

  @Mutation
  setList (payload: ISchedule[]) {
    this.schedules = payload;
    this.courseGroups = utilities
      .groupBy(payload, item => item.courseId)
      .map(([courseId, items]) => ({
        courseId: courseId,
        courseTabName: items[0].courseTabName,
        schedules: items
      }));
  }

  @Action({ rawError: true })
  async list () {
    const response = await scheduleApi.list();
    this.setList(response);
  }

  @Action({ rawError: true })
  async reserve (scheduleId: number) {
    await scheduleApi.reserve(scheduleId);
    await this.list();
  }

  @Action({ rawError: true })
  async cancel (scheduleId: number) {
    await scheduleApi.cancel(scheduleId);
    await this.list();
  }
}
