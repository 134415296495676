import { Plugin } from '@nuxt/types';
import { AxiosRequestConfig } from 'axios';
import { initializeGateway } from '~/api';
import { accountStore } from '~/store';

const GatewayAccessorPlugin: Plugin = (context) => {
  const axios = context.$axios.create({
    baseURL: process.env.BASE_URL,
    validateStatus (status) {
      return status < 400;
    }
  });

  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = accountStore.userToken
      ? {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-YouSpeak-ApiKey': process.env.API_KEY,
        Authorization: `Bearer ${accountStore.userToken!.token}`
      }
      : {
        Accept: 'application/json',
        'Access-Control-Allow-Origin': '*',
        'X-YouSpeak-ApiKey': process.env.API_KEY
      };
    return config;
  });

  initializeGateway(axios);
};

export default GatewayAccessorPlugin;
