import { AccountApi } from './AccountApi';
import { ScheduleApi } from './ScheduleApi';
import { UserApi } from './UserApi';

let accountApi: AccountApi;
let scheduleApi: ScheduleApi;
let userApi: UserApi;

export function initializeGateway (axios) {
  accountApi = new AccountApi(axios);
  scheduleApi = new ScheduleApi(axios);
  userApi = new UserApi(axios);
}

export {
  accountApi,
  scheduleApi,
  userApi
};
