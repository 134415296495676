import { AxiosInstance } from 'axios';
import { ISchedule } from '~/models';
import { ScheduleAlreadyFull } from './UserApi';

export class ScheduleApi {
  constructor (public axios: AxiosInstance) {}

  async list (): Promise<ISchedule[]> {
    const response = await this.axios.get('my/v1/schedules');
    return response.data as ISchedule[];
  }

  async reserve (scheduleId: number): Promise<void> {
    const response = await this.axios.post(`my/v1/schedules/reserve/${scheduleId}`);

    if (response.status === 205)
      throw new ScheduleAlreadyFull();
  }

  async cancel (scheduleId: number): Promise<void> {
    await this.axios.post(`my/v1/schedules/cancel/${scheduleId}`);
  }
}
